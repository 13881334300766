<template>
  <div class="content-wrapper">
    <div class="attendance-application main-content">
      <div class="page-header">
        <h1>Leaves Application</h1>
        <LeaveApplicationForm :viewMode="true":eventName="'updateApplication'" @updateApplication="updateApplication"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import LeaveApplicationForm from '@/components/hr/LeaveApplicationForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'LeaveApplication',
  components: {
    LeaveApplicationForm,
  },
  mounted(){
    this.loadApplication();
  },
  methods:{
    async loadApplication(){
      const loginInfo = Common.getLoginInfo();
      try{
        const applicationId = this.$route.params.id;
        const application = await Hr.loadLeaveApplication(this.apiUrl, applicationId, loginInfo);
        this.setCurrentApplication(application);
      }catch(err){
        console.log(err);
      }
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async updateApplication(form){
      const loginInfo = Common.getLoginInfo();
      const updateValues = {
        date: dayjs(form.date).unix(),
        startTime: dayjs(form.startTime).unix(),
        endTime: dayjs(form.endTime).unix(),
        duration: form.duration,
        status: form.status,
        reason: form.reason,
      };
      if(form.evidenceFile && form.evidenceFile.length !== 0){
        applicationInfo['evidenceFile'] = form.evidenceFile;
      }
      try{
        const applicationId = this.$route.params.id;
        const application = await Hr.updateLeaveApplication(this.apiUrl, applicationId, updateValues, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentApplication']),
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.application-list{
  td{
    text-transform: capitalize;
  }
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
